<div class="ui grid">
  <div class="column">
    <h3 class="ui header">Newsletter Einstellungen: </h3>
    @if (user) {
      <app-user-newsletter-form #newsletterFormComponent [editUser]="user"></app-user-newsletter-form>
      Letzte Email erhalten am: {{ user.abonnement?.lastMailSend | date: 'dd.MM.yyyy HH:mm' }}

      <!--SUBMIT FORM-->
      <button type="submit" class="ui right floated primary button"
              [disabled]="newsletterFormComponent.newsletterForm.invalid"
              (click)="submit(newsletterFormComponent.submit())"
      >
        Speichern
      </button>
      <button type="button" class="ui right floated secondary button"
              [disabled]="!user.abonnement?.days?.length"
              (click)="unsubscribeNewsletter()"
      >
        Abbestellen
      </button>
    }
  </div>
</div>
