@if (user) {
  <div class="ui grid">
    <div class="row">
      <div class="column">
        <h3 class="ui header">Profil Einstellungen: </h3>
        <app-user-profile-form #userForm [editUser]="user"></app-user-profile-form>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <button class="ui right floated primary button" type="submit"
                [disabled]="!userForm?.userForm?.valid"
                (click)="saveUser(userForm!.userForm.value)"
        >
          Speichern
        </button>
        <button class="ui right floated secondary button" type="button" (click)="deleteUser()">Account löschen</button>
        <button class="ui right floated secondary button" type="button" (click)="updatePassword()">Passwort ändern
        </button>
      </div>
    </div>
  </div>
}
