import {Component, OnInit} from '@angular/core';

import {SuiModalService} from '@angular-ex/semantic-ui';

import {User} from 'src/app/core/models/users/users.model';
import {UsersService} from 'src/app/core/services/users/users.service';
import {AlertModal} from '../../../../shared/modals/alert-modal/alert-modal.component';
import {KcAdminCliService} from '../../../../core/services/kc-admin-cli/kc-admin-cli.service';
import {UserNewsletterFormValues} from '../../../../shared/forms/user-newsletter-form/user-newsletter-form.component';
import {ToastService} from "../../../../core/services/toast/toast.service";

@Component({
  selector: 'app-user-newsletter-settings',
  templateUrl: './user-newsletter-settings.component.html',
  styleUrls: ['./user-newsletter-settings.component.css']
})
export class UserNewsletterSettingsComponent implements OnInit {
  user?: User;

  constructor(
    private usersService: UsersService,
    private kcAdminCliService: KcAdminCliService,
    public modalService: SuiModalService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.usersService.getCurrentUser().then(user => this.user = user);
  }

  async submit(values: UserNewsletterFormValues) {
    if (!this.user) {
      return;
    }

    try {
      // TODO why would this update the Keycloak user? This is just the newsletter settings
      const kcUser = await this.kcAdminCliService.getUser(this.user.kcId);
      kcUser.email = values.email;
      kcUser.username = values.email;
      await this.kcAdminCliService.updateOneUser(this.user.kcId, kcUser);

      const {receiveInternalMessages, ...rest} = values;
      this.user = await this.usersService.updateOne(this.user._id, {
        receiveInternalMessages,
        abonnement: {
          ...this.user.abonnement,
          ...rest,
          lastMailSend: this.user.abonnement?.lastMailSend ?? new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        },
      });
      this.toastService.success('Newsletterdaten bearbeitet', 'Die Newsletteroptionen wurden erfolgreich bearbeitet.');
    } catch (error) {
      this.toastService.error('Newsletterdaten nicht bearbeitet', 'Die Newsletterdaten konnten nicht bearbeitet werden.', error);
    }
  }

  unsubscribeNewsletter() {
    this.modalService.open(new AlertModal('Newsletter abbestellen',
      'Sind sie sich sicher, dass Sie den FIT Newsletter nicht mehr erhalten wollen?'))
      .onApprove(() => {
        if (!this.user) {
          return;
        }

        this.usersService.updateOne(this.user._id, {
          receiveInternalMessages: false,
          abonnement: {
            ...this.user.abonnement!,
            days: [],
            receiveInstitutionArticles: false,
          },
        }).then(user => {
          this.user = user;
          this.toastService.success('Newsletter abbestellt',
            'Der Newsletter wurde erfolgreich abbestellt und wird Ihnen ab jetzt nicht mehr zugestellt.');
        }, error => this.toastService.error('Newsletter nicht abbestellt', 'Der Newsletter konnte nicht abbestellt werden', error));
      });
  }
}
