import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {SuiCheckboxModule, SuiPopupModule, SuiSelectModule} from '@angular-ex/semantic-ui';

import {SettingsComponent} from './settings.component';
import {SettingsRoutingModule} from './settings-routing.module';
import {UserProfileSettingsComponent} from './components/user-profile-settings/user-profile-settings.component';
import {UserNewsletterSettingsComponent} from './components/user-newsletter-settings/user-newsletter-settings.component';
import {SharedFormsModule} from '../../shared/forms/shared-forms.module';

@NgModule({
  declarations: [
    SettingsComponent,
    UserNewsletterSettingsComponent,
    UserProfileSettingsComponent,
  ],
  imports: [
    SettingsRoutingModule,
    ReactiveFormsModule,
    SuiSelectModule,
    SuiCheckboxModule,
    CommonModule,
    SuiPopupModule,
    SharedFormsModule,
  ],
})
export class SettingsModule {
}
