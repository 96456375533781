import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '../../core/auth/auth-guard';
import {SettingsComponent} from './settings.component';
import {clientRoles} from '../../core/constants/client-roles.config';
import {UserProfileSettingsComponent} from './components/user-profile-settings/user-profile-settings.component';
import {UserNewsletterSettingsComponent} from './components/user-newsletter-settings/user-newsletter-settings.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'profile',
    pathMatch: 'full'
  },
  {
    path: '',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [
        clientRoles.IS_USER
      ]
    },
    children: [
      {
        path: 'profile',
        component: UserProfileSettingsComponent,
      },
      {
        path: 'newsletter',
        component: UserNewsletterSettingsComponent,
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'profile',
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {
}
