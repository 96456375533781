import {Component, OnInit} from '@angular/core';

import {SuiModalService} from '@angular-ex/semantic-ui';

import {KeycloakService} from 'keycloak-angular';
import {User} from '../../../../core/models/users/users.model';
import {UsersService} from '../../../../core/services/users/users.service';
import {Institution} from '../../../../core/models/institutions/institutions.model';
import {AlertModal} from '../../../../shared/modals/alert-modal/alert-modal.component';
import {KcAdminCliService} from '../../../../core/services/kc-admin-cli/kc-admin-cli.service';
import {ToastService} from "../../../../core/services/toast/toast.service";
import {UserFormValues} from '../../../../shared/forms/user-profile-form/user-profile-form.component';

@Component({
  selector: 'app-user-profile-settings',
  templateUrl: './user-profile-settings.component.html',
  styleUrls: ['./user-profile-settings.component.css']
})
export class UserProfileSettingsComponent implements OnInit {
  public user!: User;
  public institutions!: Institution[];

  constructor(
    private usersService: UsersService,
    private modalService: SuiModalService,
    private kcAdminService: KcAdminCliService,
    private keycloakService: KeycloakService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.usersService.getCurrentUser().then((user: User) => {
      this.user = user;
    });
  }

  public deleteUser(): void {
    this.modalService.open(new AlertModal('Diesen Account löschen',
      'Wollen Sie diesen Account wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden!'))
      .onApprove(async () => {
        try {
          await this.usersService.deleteOne(this.user._id);
          await this.kcAdminService.deleteOwnUser();
          this.keycloakService.logout();
        } catch (error) {
          this.toastService.error('Fehler beim löschen des Accounts!', 'Bitte wenden Sie sich unter fit@uni-kassel.de an uns, damit wir das Problem lösen können!', error);
        }
      });
  }

  public saveUser(user: UserFormValues): void {
    this.usersService.updateOne(this.user._id, user).then(updatedUser => {
      this.user = updatedUser;
      this.toastService.success('Nutzerdaten bearbeitet', 'Die Benutzerdaten wurden erfolgreich bearbeitet.');
    }, error => {
      this.toastService.error('Nutzerdaten nicht bearbeitet', 'Die Benutzerdaten konnten nicht bearbeitet werden.', error);
    });
  }

  updatePassword() {
    this.modalService.open(new AlertModal('Passwort ändern',
      'Sie können in den Einstellungen Ihr Passwort ändern. Dazu öffnet sich ein Fenster, in dem Sie das neue Passwort eingeben. Dort wird erklärt, dass nach dem Eingeben das Konto wieder freigegeben wird: "Sie müssen Ihr Passwort ändern, um das Benutzerkonto zu aktivieren"'))
      .onApprove(async () => {
        try {
          this.usersService.updatePassword().subscribe(() => {
            window.location.reload();
          });
        } catch (error) {
          this.toastService.error('Password nicht geändert', 'Das Passwort konnte nicht geändert werden. Bitte wenden Sie sich unter fit@uni-kassel.de an uns, damit wir das Problem lösen können.', error);
        }
      });
  }
}
